import { Button, Icon, LinkBox, LinkOverlay, Show, Td, Tr } from '@chakra-ui/react'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useContext } from 'react'

import ViewIcon from 'assets/view_icon.svg?component'
import { ResponsiveTableContext } from 'components/elements/ResponsiveTable/Provider/context'
import OrderStatus from 'components/modules/Order/OrderDetails/OrderStatus'
import { RoutePaths } from 'config/routes'
import assignParamsToUrl from 'utils/assignParamsToUrl'
import formatDate from 'utils/formatDate'

interface OrderHistoryTableRowProps extends OrderHeader {}

/**
 * Used for E2E tests.
 */
const TAG = 'OrderHistoryTableRow'

const OrderHistoryTableRow: FunctionComponent<OrderHistoryTableRowProps> = ({
  orderNumber,
  customerOrderNumber,
  orderDate,
  status,
}) => {
  const { lang, t } = useTranslation('orders')

  const headings = useContext(ResponsiveTableContext)

  return (
    <LinkBox as={Tr} data-link="true">
      <Td data-label={headings[0].title} maxW={{ md: '250' }} wordBreak="break-word" whiteSpace="normal">
        <LinkOverlay as={NextLink} href={assignParamsToUrl(RoutePaths.order, { slug: orderNumber })}>
          {orderNumber}
        </LinkOverlay>
      </Td>
      <Td data-label={headings[1].title}>{customerOrderNumber}</Td>
      <Td data-label={headings[2].title}>{formatDate(orderDate, lang)}</Td>
      <Td data-label={headings[3].title}>
        <OrderStatus status={status} />
      </Td>
      <Show below="sm">
        <Td justifyContent="center" mt="4">
          <Button
            w="full"
            leftIcon={<Icon aria-hidden="true" as={ViewIcon} boxSize={4} color="primary.blue" />}
            href={assignParamsToUrl(RoutePaths.order, { slug: orderNumber })}
            as={NextLink}
          >
            {t('pages.order.table.view')}
          </Button>
        </Td>
      </Show>
    </LinkBox>
  )
}

OrderHistoryTableRow.displayName = TAG

export default OrderHistoryTableRow
