export const OrderStatuses = {
  OrderCreationNotCompleted: '20',
  Reserved: '22',
  Allocated: '33',
  ReadyForDelivery: '44',
  Shipped: '66',
  Completed: ['77', '99'],
} as const satisfies Record<string, string | readonly ['77', '99']>

export type OrderStatusesKeys = keyof typeof OrderStatuses
export type OrderStatusesValues = (typeof OrderStatuses)[OrderStatusesKeys]

export type OrderStatusesFlattenValues = (typeof OrderStatuses)[OrderStatusesKeys] extends infer Element
  ? Element extends ReadonlyArray<infer ElementType>
    ? ElementType
    : Element
  : never

export const OrderStatusesColor = {
  [OrderStatuses.OrderCreationNotCompleted]: 'primary.red',
  [OrderStatuses.Reserved]: 'primary.black',
  [OrderStatuses.Allocated]: 'gray.600',
  [OrderStatuses.ReadyForDelivery]: 'primary.yellow',
  [OrderStatuses.Shipped]: 'primary.lightBlue',
  [OrderStatuses.Completed[0]]: 'primary.willowGreen',
  [OrderStatuses.Completed[1]]: 'primary.willowGreen',
} as const satisfies Record<OrderStatusesFlattenValues, string>
