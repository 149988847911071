import { Tr, Th, forwardRef, Box } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import ResponsiveTable from 'components/elements/ResponsiveTable'
import type { TableHeadingType } from 'components/elements/ResponsiveTable/Provider/context'
import OrderHistoryTableRow from 'components/modules/OrderHistory/OrderHistoryTableRow'

interface OrderHistoryTableProps {
  orders: ReadonlyArray<OrderHeader>
  ref?: React.Ref<HTMLDivElement>
}

/**
 * Used for E2E tests.
 */
const TAG = 'OrderHistoryTable'

const OrderHistoryTable: FunctionComponent<OrderHistoryTableProps> = forwardRef(({ orders }, ref) => {
  const { t } = useTranslation('orders')

  const tableHeadings = [
    { title: t('pages.orderHistory.table.orderNumber') },
    { title: t('pages.orderHistory.table.yourRefNumber') },
    { title: t('pages.orderHistory.table.orderDate') },
    { title: t('pages.orderHistory.table.status') },
  ] as ReadonlyArray<TableHeadingType>

  return (
    <Box ref={ref}>
      <ResponsiveTable headings={tableHeadings}>
        {orders.length ? (
          orders.map((order) => <OrderHistoryTableRow {...order} key={order.orderNumber} />)
        ) : (
          <Tr>
            <Th colSpan={tableHeadings.length}>{t('pages.orderHistory.table.noOrders')}</Th>
          </Tr>
        )}
      </ResponsiveTable>
    </Box>
  )
})

OrderHistoryTable.displayName = TAG

export default OrderHistoryTable
